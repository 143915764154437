import { APIGATEWAY_BASE_URL } from 'src/dataLayer/env';
import makeAjaxRequest from './../makeAjaxRequest';
import { formatCaptchaResult } from 'src/viewLayer/components/shared/CaptchaFeature/helpers';

export const ERROR_KEYS = {
  422: {
    key: null
  },
  400: {
    key: 'common.error.unknown'
  },
  default: {
    key: 'password.error.invalid_credentials'
  }
};

const getPayload = ({
  email,
  password,
  sessionCode,
  captchaResponseToken
}) => {
  const payload = {
    token: {
      email,
      password,
      session: {
        code: sessionCode,
        captcha: formatCaptchaResult(captchaResponseToken)
      }
    }
  };

  return payload;
};

export default (props) =>
  makeAjaxRequest({
    verb: 'POST',
    payload: getPayload(props),
    url: `${APIGATEWAY_BASE_URL}/v1.0.0/${props.locale}/authentication/token`,
    errorKeys: ERROR_KEYS
  }).then((response) => response.token.value);
